import { getRandomValues, subtle } from "uncrypto";

export const isDemo = () => useAppConfig().isDemo;

// @link https://stackoverflow.com/a/62504318/2527692
export const randomString = (n: number) =>
  import.meta.dev
    ? "123456"
    : [...getRandomValues(new Uint8Array(n))]
        .map(
          (x, i) => (
            (i = ((x / 255) * 61) | 0), String.fromCharCode(i + (i > 9 ? (i > 35 ? 61 : 55) : 48))
          )
        )
        .join("");

export const getHash = async (file: File): Promise<string> =>
  file
    .arrayBuffer()
    .then((buffer) => subtle.digest("SHA-256", buffer))
    .then((hash) =>
      Array.from(new Uint8Array(hash))
        .map((b) => b.toString(16).padStart(2, "0"))
        .join("")
    );
